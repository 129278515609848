import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/ScrollToTop.jsx";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import Home from "./pages/Homepage.jsx";
import AboutUs from "./pages/AboutPage.jsx";
import Testimonials from "./pages/Testimonials.jsx";
import GrandDesign from "./pages/GrandDesign.jsx";
import Renovation from "./pages/Renovation.jsx";
import Commercial from "./pages/Commercial.jsx";
import LightCommercial from "./pages/LightCommercial";
import NewBuilds from "./pages/NewBuilds.jsx";
import Residential from "./pages/Residential.jsx";
import Enquire from "./pages/Enquire.jsx";
import Contact from "./pages/ContactPage.jsx";
import NotFound from "./pages/NotFound";
import Blog from "./pages/Blog.jsx";
import SingleBlog from "./pages/SingleBlog.jsx";
import Page from "./pages/Page.jsx";
import TOS from "./pages/TermsOfServicePage.jsx";
import Service from "./components/Service";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/css/normalize.css";
import "./assets/css/global.scss";
import "./assets/js/common.js";

const App = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  document.body.classList.add("page");

  return (
      <div className="App">
        <Helmet>
          <meta name="robots" content="index" />
          <link rel="canonical" href={window.location.href} />
          <title>SB2 Build Ltd | Builders Mosgiel, Dunedin, Otago</title>
        </Helmet>
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:slug" element={<Page />} />
            <Route path="/home-building-companies-dunedin" element={<AboutUs />} />
            <Route path="/recommended-builder-dunedin" element={<Testimonials />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<SingleBlog />} />
            <Route path="/residential/build-grand-designs" element={<GrandDesign />} />
            <Route path="/build-grand-designs" element={<Navigate to="/residential/build-grand-designs" />} />
            <Route path="/residential/house-builders-dunedin" element={<NewBuilds />} />
            <Route path="/house-builders-dunedin" element={<Navigate to="/residential/house-builders-dunedin" />} />
            <Route path="/residential" element={<Residential />} />
            <Route path="/services" element={<Navigate to="/residential" />} />
            <Route path="/residential/home-renovations-dunedin" element={<Renovation />} />
            <Route path="/home-renovations-dunedin" element={<Navigate to="/residential/home-renovations-dunedin" />} />
            <Route path="/commercial" element={<Commercial />} />
            <Route path="/commercial/commercial-builder-dunedin" element={<LightCommercial />} />
            <Route path="/commercial-builder-dunedin" element={<Navigate to="/commercial/commercial-builder-dunedin" />} />
            <Route path="/commercial/:slug" element={<Service />} />
            <Route path="/bathroom-renovations-dunedin" element={<Enquire />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/terms-of-service" element={<TOS />} />
            <Route path="/enquire" element={<Navigate to="/bathroom-renovations-dunedin" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </div>
  );
};

export default App;